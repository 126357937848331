.recipe-details {
  width: 100%;
  margin: 2rem 0;

  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .back-button {
    margin-bottom: 2rem;
  }

  .details-wrapper {
    display: flex;
    flex-direction: column;

    .image-wrapper,
    .details {
      width: 100%;
      margin-bottom: 1rem;
    }

    .image {
      width: 100%;
    }
  }

  .title {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  .button {
    display: inline-block;
    margin: 1rem;
  }

  .ingredients-wrapper {
    margin: 1rem 0 0 1rem;
  }

  @media only screen and (min-width: 600px) {
    .details-wrapper {
      flex-direction: row-reverse;

      .image-wrapper,
      .details {
        width: 50%;
      }
    }
  }
}
